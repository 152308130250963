
const PermissionPopup = (props) => {

    return (
        <div className="absolute top-0 camera-error-popup bg-black/[0.3] backdrop-blur-sm h-full w-full flex justify-center items-center px-6">
            <div className=" flex flex-col bg-white p-5 rounded">
                <p className=" text-2xl font-bold text-center py-3">INFO</p>
                <p className="pb-5">   Looks like you disabled camera permission. Swipe the screen down and accept camera permission to continue. You may also go to settings and enable camera permission</p>
                <p className=" font-normal text-sm text-black">
                    <span className=""> Need help? Visit our </span>
                    <span className="font-bold pl-2">
                        <a href="mailto:support@toothlens.com"> Help Center </a>
                    </span>
                </p>
                <button
                    type="button"
                    onClick={() => window.history.back()}
                    className="bg-black text-white px-5 h-11 font-semibold text-base rounded self-end">Ok</button>
            </div>
        </div>
    )
}

export default PermissionPopup