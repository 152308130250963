import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Shoot from "./shoot"


const FullScan = () => {

    const [isToothPicker, setToothPicker] = useState(true)
    const [activeImageSide, setImageSide] = useState(0)
    const navigate = useNavigate()
    useEffect(()=>{
    if(!localStorage.getItem("usertpi")|| localStorage.getItem("imageName")){
        navigate('/')
    }
    },[])
   
    return (
        <div className="h-full">
            {
                // isToothPicker ?
                    // <ToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                    <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} setImageSide={setImageSide} />
            }
        </div>
    )
}

export default FullScan