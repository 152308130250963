import { UAParser } from 'ua-parser-js';

const parser = new UAParser().getResult();

export const GetBrowserDetails = () => {
    return parser.browser;
};

export const GetDeviceDetails = () => {
    return parser.device;
};

export const GetOSDetails = () => {
    return parser.os;
};

export const GetFullDetails = () => {
    return parser
};

export const getDesktopOrMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      
        // Check for iPad, including iPad Pro and Air
        const isIpad = /iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document;
        
        // Check for Android tablets (excludes mobile Android)
        const isAndroidTablet = /Android/i.test(userAgent) && !/Mobile/i.test(userAgent);
      
        // Check for other mobile devices (iPhone, Android Phones)
        const isMobile = /iPhone|Android/i.test(userAgent) && /Mobile/i.test(userAgent);
      
        // Get screen width for additional validation
        const isTabletByWidth = window.innerWidth >= 600 && window.innerWidth <= 1024;
      
        // Ensure false for all tablets (iPads, Samsung Tabs, etc.) and mobile devices
        if (isIpad || isAndroidTablet || isMobile || isTabletByWidth) {
          return false;
        }
      
        // Return true for desktop devices
        return true;
      
  };
  
  