import { API_HOST } from "../BaseUrl";
import client, { headers } from "./config";

export const imageUpload = ({ formData, folderName }) => {
  const updatedHeaders = {
    ...headers,
    "Content-Type": "multipart/form-data",
    folderName,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(API_HOST + `report/image`, formData, {
        headers: updatedHeaders,
      });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response.data);
    } catch (error) {
      reject(error);
    }
  });
};


export const getUploadedImages = () => {
    const updatedHeaders = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.post(API_HOST + `read/image`, {imageName:localStorage.getItem("imageName")}, {
          headers: updatedHeaders,
        });
        if (response.status === 200) {
          resolve(response);
        }
        reject(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  export const getInputImage = () => {
    const updatedHeaders = {
      ...headers,
      "Content-Type": "multipart/form-data",
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.post(API_HOST + `read/one/image`, {imageName:localStorage.getItem("imageName")}, {
          headers: updatedHeaders,
        });
        if (response.status === 200) {
          resolve(response);
        }
        reject(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  