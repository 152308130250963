import React from 'react';
import TintLogo from './../../assets/LandingPageImages/simmetry.png';
import './index.css'
import backIcon from "../../assets/Icons/arrow-back.jpg"
import { useNavigate } from 'react-router-dom';

const UserMail = () => {
  const navigate = useNavigate();

  return (
    <div className='user-mail-bg h-[100vh] bg-no-repeat'>
      <div className='py-[20px] px-[15px]'>
        <button
          className='rounded-full bg-white border-4 border-white m-2 absolute top-2 left-3'
          onClick={() => { navigate('/') }}>
          <img
            src={backIcon} alt='Back' className=' h-6 rounded-full' />
        </button>
      </div>
      <div className=' py-[30px] pl-[30px] pr-[15px] flex flex-col h-[92%] justify-end items-start'>
        <img src={TintLogo} className='h-14 universal-button rounded-lg p-2' alt='tint-logo' />
        <h2 className='text-[32px] text-[#022022] my-[20px] font-medium'>
          Enter Your Email Address to Get Your Photos!
        </h2>
        <input className='p-[15px] w-[100%] my-[15px] rounded-[0.5rem] bg-[#D4D4D4] border-[1px] border-[#D4D4D4] text-[#000] text-center'
          type='text' placeholder='Type your email address here' />
        <button
          type="button"
          className="text-base font-red-hat h-12 rounded-lg  w-full  text-white universal-button my-[15px]"
        >
          Download
        </button>
        <p className='text-[12px] text-[#808F90]'>
          Your data is shared only with authorized dental offices,
          securely handled for dental care purposes. We prioritize your privacy and comply with all relevant regulations.
        </p>
      </div>
    </div>
  )
}

export default UserMail