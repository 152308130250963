import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./Components/Landing";
import FullScan from "./Components/Scan/fullScan";
import PageNotFound from "./Components/PageNotFound";
import OnBoarding from "./Components/OnBoarding";
import Whiten from "./Components/Whiten";
import QrCode from "./Components/QrCode";
import Landscape from "./Components/Landscape";
import { getDesktopOrMobile } from "./Utils/deviceDetails";
import UserDetails from "./Components/SuccessPage/userDetails";
import UserMail from "./Components/SuccessPage/userMail";
import "./App.css"

function App() {

  const [isDesktop, setDeviceType] = useState(false)
  const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight)
  const handleOrientation = () => {
    detectDevice()
    setLandscape(window.innerWidth > window.innerHeight)
    document.documentElement.style.setProperty("--browser-offset", `calc(var(--vh, 1vh) * 100 - ${window.innerHeight}px)`);
  }

  const detectDevice = () => {
    const isDesktopWindows = getDesktopOrMobile()
    setDeviceType(isDesktopWindows)
  }

  useEffect(() => {
    window.addEventListener("resize", handleOrientation)
    handleOrientation()
    // window.open()
    return () => {
      window.removeEventListener("resize", handleOrientation)
    }
  }, [])


  return (
    <div className="App">
      {isDesktop ? (
        <Routes>
          <Route path="/" element={<QrCode />} />
          <Route path="*" element={<QrCode />} />
        </Routes>
      ) : isLandscape ? (
        <Landscape />
      ) : (
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/full-scan" element={<FullScan />} />
          <Route exact path="/on-boarding" element={<OnBoarding />} />
          <Route exact path="/whiten-track" element={<Whiten />} />
          <Route exact path="/download" element={<UserDetails/>} />
          <Route exact path="/share" element={<UserMail />} />

          <Route path="*" element={<LandingPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
