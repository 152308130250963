export const getCroppedImg = async (imageSrc) => {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
      });
  
    const image = await createImage(imageSrc);
    
    // Calculate the cropping dimensions
    const cropHeight = image.height * 0.6; // Crop 80% of the image's height
    const cropY = image.height * 0.2; // Start cropping from 10% down from the top
  
    // Create a canvas with the new dimensions
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const cropWidth = image.width*0.6; // Keep full width
     const cropX =  image.width * 0.2;; // No cropping from the sides
    canvas.width = cropWidth; // Full width
    canvas.height = cropHeight; // 80% of the height
  
    // Draw the cropped portion of the image onto the canvas
    ctx.drawImage(
      image,
      cropX, cropY, // Starting point (X: 0, Y: 10% from top)
      image.width, cropHeight, // Crop full width and 80% height
      0, 0, // Draw from top-left corner of the canvas
      image.width, cropHeight // Full width and cropped height in canvas
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };
  