import ErrorImg from "../../assets/ReshootErrorImage/Frame 1686561489.jpg"
import close from "../../assets/Icons/close-black.jpg"
import invalidImage from "../../assets/ReshootErrorImage/invalid-image.jpg"
import poorLight from "../../assets/ReshootErrorImage/poor-light.jpg"
import { useNavigate } from "react-router-dom";

const ReshootPopup = ({closeReshoot,onRescan,setDemo}) => {
    const navigate=useNavigate()
    return ( 
        <div className="h-full w-full absolute top-0 z-10 bg-black/[0.4] backdrop-blur-sm flex justify-center items-center" >
            <div className="bg-white rounded-xl py-7 px-5 w-10/12 max-w-96">
                <div className="flex justify-center items-start relative">
                    <img src={ErrorImg} alt="Error" className=" h-24 sm600:h-48" />
                    <button onClick={() => {
                         closeReshoot()
                     }} className="absolute top-2 right-2">
                        <img src={close} alt="close" className=" h-5 sm600:h-10" />
                    </button>
                </div>
                <p className=" text-center font-bold text-xl text-red-500 py-3 sm600:text-2xl">Error!</p>

                <div className="font-medium text-base text-[#022022] py-4" >
                    <p className=" sm600:text-2xl  font-urbanist">Improper photo, this may be due to</p>
                    <ul>
                        <li className="flex mt-2">
                            <img src={poorLight} className=" h-6 me-2" alt="ibvalid" />
                            <p className=" sm600:text-2xl  font-urbanist"> Poor lighting </p>
                        </li>
                        <li className="flex mt-2">
                            <img src={invalidImage} className="h-6 me-2" alt="poor loght" />
                            <p className=" sm600:text-2xl  font-urbanist"> The image does-not match the reference image</p>
                        </li>
                    </ul>
                </div>

                <div className="flex flex-col items-center mt-3">
                    <button
                        type="button"
                        className=" h-12 rounded-lg w-4/5 border font-red-hat  border-gray-900 text-base font-semibold  font-urbanist bg-gray-100 text-[#022022]  sm600:text-2xl"
                        onClick={() => { 
                            console.log("navigate")
                          setDemo(true)}}>
                        View reference
                    </button>
                    <button
                        type="button"
                        className="universal-button h-12 rounded-lg w-4/5 text-base font-semibold  font-red-hat  text-white mt-3  sm600:text-2xl"
                        onClick={() => {
                            onRescan()
                            }}>
                        Retake scan
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ReshootPopup