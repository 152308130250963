import React, { useEffect } from "react";
import img2 from "../../assets/LandingPageImages/landingImage.jpg";
import img4 from "../../assets/LandingPageImages/landingImg4.jpg";
import logo from "../../assets/LandingPageImages/simmetry.png";
import { useNavigate } from "react-router-dom";
import { generateRandomAlphaNumeric } from "../../Utils/alphaNumeric";
import toothlens_logo from "../../assets/LandingPageImages/toothlens_logo.png";
const LandingPage = () => {
  localStorage.setItem(
    "companyBucket",
    JSON.stringify({ companyBucket: "toothlens" })
  );
  const navigate = useNavigate();
  const takeScan = () => {
    const randomString = generateRandomAlphaNumeric(2);
    const timestamp = Date.now().toString();
    const randomString2 = generateRandomAlphaNumeric(1);
    const unique = randomString + timestamp + randomString2;
    localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
    navigate(`/full-scan`);
  };

  useEffect(() => {
    localStorage.clear()
    const hasReloaded = sessionStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <div className="bg-white w-full  h-full flex flex-col">
      <div className=" h-1/2  relative w-full flex justify-end items-start pl-4 ">
        <img src={img2} alt="ai-teeth" className="h-full w-full  " />
        <div className="absolute top-0 left-0  h-full w-full flex justify-start pl-8 items-center">
          <img src={logo} className="p-2 universal-button rounded-lg h-14 sm600:h-24" />
        </div>
      </div>
      <div className="flex flex-col justify-between items-start h-1/2 ">
        <div className="flex w-full justify-between  ">
          <p className="ml-8 mt-1 font-red-hat font-semibold  text-3xl sm600:text-6xl py-2 text-dark-gray">
            Virtual smile <br /> simulator
          </p>
          <div className="-mt-8 text-end">
            <img src={img4} alt="background-design" className="h-36 sm600:h-52" />
          </div>
        </div>
        <p className="ml-8 py-1 first-letter:font-red-hat  text-custom-gray  text-xl sm600:text-3xl ">
          Smile, snap a selfie, and see
          <br /> the magic!
        </p>
        <div className="ml-8 py-2">
          <p className="py-2 first-letter:font-red-hat  text-custom-gray  sm600:text-3xl ">
            Powered by
          </p>
          <img src={toothlens_logo} alt="logo" className="h-8 sm600:h-10" />
        </div>
        <div className="  mt-2 w-full px-8 mb-8 text-center">
          <button
            type="button"
            className="text-base font-red-hat   h-12 rounded-lg  w-full  text-white universal-button sm600:text-2xl"
            onClick={() => {
              takeScan();
            }}
          >
            Scan
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
