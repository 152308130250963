import { configureStore } from "@reduxjs/toolkit";
import fullScanReducer from "./fullScan";
import reportsReducer from "./reports"


export const store = configureStore({
    reducer: {
        fullScan: fullScanReducer,
        reports: reportsReducer,
    }
})

export default store