import { createSlice } from "@reduxjs/toolkit"
import { generateRandomAlphaNumeric } from "../Utils/alphaNumeric"

const initialState = {
    images: [],
    fullScanS3Folder: ''
}

const fullScanSlice = createSlice({
    name: 'fullScan',
    initialState,
    reducers: {
        clearImages: () => {
            return initialState
        },
        addImages: (state, action) => {
            state.images = [...state.images, action.payload]
        },
        createFullScanS3Folder: (state) => {
            const randomString = generateRandomAlphaNumeric(2);
            const timestamp = Date.now().toString();
            const randomString2 = generateRandomAlphaNumeric(1)
            state.fullScanS3Folder = randomString + timestamp + randomString2
        },
        clearFullScanfolder: (state) => {
            state.fullScanS3Folder = ''
        }
    }
})

export default fullScanSlice.reducer
export const { clearImages, addImages, createFullScanS3Folder, clearFullScanfolder } = fullScanSlice.actions