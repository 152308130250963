import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import logo from "../../assets/LandingPageImages/simmetry.png";


const QrCode = () => {
  const [qrCode, setQrcode] = useState("");
  


  useEffect(() => {
    QRCode.toDataURL(window.location.href).then((data) => {
      setQrcode(data);
    });
  }, []);

  
    return (
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-full min-w-96 max-w-xl bg-slate-100">
          <div className=" universal-button flex justify-center items-center py-3">
            <div className="bg-black w-28 h-24 rounded-full flex justify-center items-center">
              {" "}
              <img
                src={logo }
                className="object-content h-12"
                alt="logo"
              />{" "}
            </div>
            {/* <p className="text-white font-semibold text-xl ms-4">TOOTHLENS</p> */}
          </div>
          <div>
            <h1 className=" text-xl font-semibold text-center mt-10">
              THANKS FOR VISITING
            </h1>
            <p className="text-center mt-3 leading-6">
             Please scan to use the App{" "}
              <br />
              or type{" "}
              <span className="font-bold"> {window.location.href} </span> in
              your  browser.
            </p>
          </div>
          <div className="mt-8">
            {qrCode && (
              <img
                className=" h-72 mx-auto shadow-xl"
                src={qrCode}
                alt="Qr code"
              />
            )}
          </div>
        </div>
      </div>
    );
  
};

export default QrCode;