import React, { useState, useEffect } from "react";
import backIcon from "../../assets/Icons/arrow-back.jpg";
import tintLogo from "../../assets/LandingPageImages/simmetry.png";
import { Box, Slider } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { getUploadedImages, getInputImage } from "../../Services/APIs/scan";

const Whiten = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [images, setImages] = useState([]); // State to store fetched images
  const [error, setError] = useState(null); // Error state
  const [inputImage, setInputImage] = useState();
  const marks = [
    { value: 0, label: "00" },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    // { value: 5, label: '05' },
    // { value: 6, label: '06' }
  ];

  const handleChange = (event, value) => {
    setValue(value); // Update the slider value
  };

  // Function to fetch images from the API and convert to URLs
  const getImageData = async () => {
    try {
      const res = await getUploadedImages();
      const imageArray = res.data.data

      // Convert each binary image data to a URL
      const fetchedImages = imageArray.map((binaryImage) => {
        const byteArray = new Uint8Array(binaryImage.data);
        const blob = new Blob([byteArray], { type: "image/jpeg" }); // Adjust type if necessary
        return URL.createObjectURL(blob); // Create URL for each image
      });
      const resInputImage = await getInputImage();
      const inputImage = resInputImage.data.data.data;
      const byteInput = new Uint8Array(inputImage);
      const blob = new Blob([byteInput], { type: "image/jpeg" }); // Adjust type if necessary
      const inputUrl = URL.createObjectURL(blob); // Create URL for each image
      setInputImage(inputUrl);
      // console.log(fetchedImages.length,fetchedImages.length,fetchedImages)
      
    
      setImages(fetchedImages); // Store image URLs in state
    } catch (err) {
      console.log("Error fetching images:", err);
      setError("Failed to fetch images.");
      navigate('/')
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("imageName")) {
      navigate("/");
    }
    getImageData(); // Fetch images when the component mounts
  }, []);

  return (
    <div className="h-full font-medium flex flex-col">
      {images.length > 0 ? (
        <div className="h-full font-medium flex flex-col">
          <div className="flex-grow py-3 flex flex-col items-center justify-center bg-gray-200 m-4 rounded-lg relative overflow-hidden">
            <button
              className="rounded-full z-10 bg-white border-4 border-white m-2 absolute top-2 left-2"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={backIcon} alt="Back" className="h-6 rounded-full" />
            </button>
            <div className="w-full absolute top-3 flex justify-center mt-3">
              <img
                src={tintLogo}
                className="h-5 universal-button rounded-lg px-2 py-1 sm600:h-12"
                alt="tint logo "
              />
            </div>
            {/* Display the fetched image based on the slider value */}
            <div className="flex justify-between w-full space-x-4">
              <div className="w-1/2 flex justify-center">
                <img
                  src={inputImage}
                  alt={`original-image`}
                  className=" py-4 rounded-3xl object-contain h-auto w-full "
                />
              </div>
              <div className="w-1/2 flex justify-center">
                <img
                  src={images[value]}
                  alt={`${value}`}
                  className=" py-4 rounded-3xl object-contain h-auto w-full"
                />
              </div>
            </div>
            {error && <p className="text-red-500">{error}</p>}{" "}
            {/* Show error if any */}
          </div>

          <div className="flex flex-col px-7 pb-5">
            <div className="flex items-center ">
              <Box className="slider-container ">
                <Slider
                  value={value}
                  onChange={handleChange}
                  min={0}
                  max={images.length - 1} // Adjust max to length of images array
                  step={1}
                  marks={marks.map((mark) => ({
                    value: mark.value,
                    label: mark.value === value ? "" : mark.label,
                  }))}
                  valueLabelDisplay="on"
                  className="custom-slider"
                />
              </Box>
            </div>

            <p className="text-center text-base font-medium mt-1 mb-2 sm600:text-3xl">
              Adjust Slider for <br /> Smile Options
            </p>
            <button
              type="button"
              className="text-base font-semibold h-12 rounded-lg w-full mx-auto text-white universal-button my-1 sm600:text-xl"
              onClick={() => navigate("/share")}
            >
              Next
            </button>
            <p className="text-center my-2 text-xs opacity-60 sm600:text-lg">
              AI-generated results for informational purposes only. Outcomes may
              vary. Consult a professional for personalized advice.
            </p>
          </div>
        </div>
      ) : (
        <div className="full-screen-skeleton h-full w-full">
          <div className="skeleton-content h-full w-full">
            <div className="skeleton-element font-red-hat font-semibold relative skeleton-image flex justify-center items-center h-full w-full sm600:text-3xl">
              Fetching your pics...
              <div className="w-full absolute top-0 flex justify-center mt-3">
                <img
                  src={tintLogo}
                  className="h-8 universal-button rounded-lg px-2 py-1 sm600:h-12"
                  alt="tint logo "
                />
              </div>
            </div>
            <div className="skeleton-element"></div>
            <div className="skeleton-element"></div>
            <div className="skeleton-element"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Whiten;
